<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage']('French Section')}}</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">


          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.name"
                rules="required"
                name="The Name"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Name')"
                          name="name"
                          @keyup="generateSomeFields"
                          fou
                          v-model="formData.name">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.url"
                rules="required"
                name="The URL"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('URL')"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>


          </div>

          <div class="form-group pt-4">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>

      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'fr'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            name: "",
            url: "",
            is_active: true,
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
        imagesExtensions: this.getImagesExtensions(),
        videoExtensions: this.getVideoExtensions(),
      };
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if (!this.editMode){
          let newUrl = '';
          if (this.formData.name) {
            newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
            newUrl = this.removeAccents(newUrl);
            newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
            newUrl = newUrl.replace(/([-]+)/g, "-");
          }

          this.formData.url = newUrl;
        }
      },
    }
  }
</script>
<style>
.team_list_items{
  border: 1px solid #cccc;
  padding: 10px 45px 30px 19px;
}
</style>
